import { useEffect, useState, useCallback, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import axios from "axios";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import { Box } from "@material-ui/core";

import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer/Footer";
import Landing from "./views/Landing/Landing";
import Liquidity from "./views/Liquidity/Liquidity";
import { ethers } from "ethers";

import "./style.scss";
import Notification from "./components/Notification";
import styled from "styled-components";

import { FLEXIBLE_ADDR, PAIR_ADDR, LOCK_ADDR } from "./abis/address";
import {
  getFlexibleContract,
  multicall,
  getPairContract,
} from "./utils/contracts";
import FlexibleABI from "./abis/FlexibleABI.json";
import PairABI from "./abis/PancakePairABI.json";
import LockABI from "./abis/LockABI.json";

let loadflex = false,
  loadlock = false,
  flexid = null,
  tokenid = null,
  loadtoken = false;

function App() {
  const { connect, hasCachedProvider } = useWeb3Context();

  const [notification, setNotification] = useState(null);

  const [flexinfo, setFlexInfo] = useState({ address: FLEXIBLE_ADDR });
  const [locks, setLocks] = useState([
    { address: LOCK_ADDR },
    { address: LOCK_ADDR },
  ]);
  const [balance, setBalance] = useState(0);
  const [ethPrice, setEthPrice] = useState(0);
  const [reserves, setReserves] = useState({ _reserve0: 0, _reserve1: 0 });
  const [totalSupply, setTotalSupply] = useState(0);
  const [price, setPrice] = useState({ price: 0, farmprice: 0 });

  const account = useAddress();

  const formatBigNumber = (value) => {
    if (!value) return 0;
    return ethers.utils.formatEther(value);
  };

  async function fetchTokenData() {
    loadtoken = true;
    try {
      let _ethprice = await axios.get(
        `https://api.coingecko.com/api/v3/coins/elastos/market_chart/range?vs_currency=usd&from=${
          Date.now() / 1000 - 3600
        }&to=${Date.now() / 1000}`
      );
      _ethprice = _ethprice.data.prices[_ethprice.data.prices.length - 1][1];
      setEthPrice(_ethprice);
      let calls = [
        {
          address: PAIR_ADDR,
          name: "getReserves",
          params: [],
        },
        {
          address: PAIR_ADDR,
          name: "totalSupply",
          params: [],
        },
      ];
      if (account) {
        calls.push({
          address: PAIR_ADDR,
          name: "balanceOf",
          params: [account],
        });
      }
      const result = await multicall(PairABI, calls);
      setBalance(account ? result[2][0] : 0);
      setReserves(result[0]);
      setTotalSupply(formatBigNumber(result[1][0]));
      const _price = (result[0][0] * Number(_ethprice)) / result[0][1];
      const price =
        (2 *
          Math.sqrt(
            Number(formatBigNumber(result[0][0])) *
              Number(formatBigNumber(result[0][1]))
          ) *
          Math.sqrt(_price * _ethprice)) /
        Number(formatBigNumber(result[1][0]));
      console.log(
        _price,
        price,
        _ethprice,
        formatBigNumber(result[0][0]),
        formatBigNumber(result[0][1]),
        formatBigNumber(result[1][0])
      );
      setPrice({ price: _price, farmprice: price });
    } catch (error) {}
    loadtoken = false;
  }

  async function fetchFlexibleData() {
    loadflex = true;
    try {
      let calls = [
        {
          address: FLEXIBLE_ADDR,
          name: "poolInfo",
          params: [0],
        },
        {
          address: FLEXIBLE_ADDR,
          name: "performanceFee",
          params: [],
        },
        {
          address: FLEXIBLE_ADDR,
          name: "rewardPerBlock",
          params: [],
        },
      ];
      if (account) {
        calls.push({
          address: FLEXIBLE_ADDR,
          name: "pendingRewards",
          params: [0, account],
        });
        calls.push({
          address: FLEXIBLE_ADDR,
          name: "userInfo",
          params: [0, account],
        });
      }
      const data = await multicall(FlexibleABI, calls);
      let userinfo;
      const pendingRewards = account ? formatBigNumber(data[3][0]) : 0;
      const _userinfo = account ? data[4] : { amount: 0, maxamount: 0 };
      userinfo = {
        amount: _userinfo.amount,
        maxamount: _userinfo.amount.toString(),
      };

      const pool = data[0];

      const performanceFee = data[1][0];
      const _rewardPerBlock = data[2][0];

      calls = [
        {
          address: PAIR_ADDR,
          name: "balanceOf",
          params: [FLEXIBLE_ADDR],
        },
      ];
      if (account)
        calls.push({
          address: PAIR_ADDR,
          name: "allowance",
          params: [account, FLEXIBLE_ADDR],
        });
      const result = await multicall(PairABI, calls);

      const totalStaked = result[0][0];
      const allowance = account
        ? result[1][0] > ethers.utils.parseEther("10000")
        : false;

      const rate =
        price.farmprice && totalStaked / 1
          ? (_rewardPerBlock * 28800 * 36500 * price.price) /
            (totalStaked * price.farmprice)
          : null;

      setFlexInfo({
        address: FLEXIBLE_ADDR,
        pendingRewards: pendingRewards,
        depositFee: pool.depositFee / 100,
        withdrawFee: pool.withdrawFee / 100,
        stakedAmount: userinfo.amount,
        maxStakedAmount: userinfo.maxamount,
        performanceFee: performanceFee,
        rate: rate,
        price: price.farmprice,
        balance: balance,
        allowance,
        totalStaked: formatBigNumber(totalStaked),
      });
    } catch (error) {
      console.log(error);
    }
    loadflex = false;
  }

  async function fetchLockData() {
    loadlock = true;
    try {
      let temp = [];
      const calls = [
        {
          address: LOCK_ADDR,
          name: "performanceFee",
          params: [],
        },
      ];
      const result = await multicall(LockABI, calls);
      const performanceFee = result[0][0];
      let allowance = false;
      if (account) {
        const calls = [
          {
            address: PAIR_ADDR,
            name: "allowance",
            params: [account, LOCK_ADDR],
          },
        ];
        const result = await multicall(PairABI, calls);
        allowance = result[0][0] > ethers.utils.parseEther("10000");
      }
      for (let i = 0; i < 2; i++) {
        let calls = [
          {
            address: LOCK_ADDR,
            name: "pools",
            params: [i],
          },
        ];
        if (account) {
          calls.push({
            address: LOCK_ADDR,
            name: "getPendingReward",
            params: [i, account],
          });
          calls.push({
            address: LOCK_ADDR,
            name: "withdrawableAmount",
            params: [i, account],
          });
        }
        console.log(calls);
        const result = await multicall(LockABI, calls);
        console.log(result);
        const rate =
          result[0].totalStaked / 1
            ? (((result[0].rewardPerBlock * price.price) / (i === 0 ? 7 : 15)) *
                365) /
              (result[0].totalStaked * price.farmprice)
            : null;
        temp.push({
          address: LOCK_ADDR,
          maxStakedAmount: account ? result[2][0] : 0,
          performanceFee,
          balance: balance,
          totalStaked: formatBigNumber(result[0].totalStaked),
          depositFee: result[0].depositFee / 10,
          withdrawFee: result[0].depositFee / 10,
          totalStaked: ethers.utils.formatEther(result[0].totalStaked),
          pendingRewards: account ? ethers.utils.formatEther(result[1][0]) : 0,
          claimableReward: account ? ethers.utils.formatEther(result[1][1]) : 0,
          stakedAmount: account ? result[2][0] : 0,
          withdrawableAmount: account ? result[2][1] : 0,
          price: price.farmprice,
          allowance,
          index: i,
          rate,
        });
      }
      console.log(temp);
      setLocks(temp);
    } catch (error) {
      console.log(error);
    }
    loadlock = false;
  }

  useEffect(() => {
    if (flexid) clearInterval(flexid);
    flexid = setInterval(function () {
      if (!loadflex) fetchFlexibleData();
      if (!loadlock) fetchLockData();
    }, 500);
  }, [account, balance, price]);

  useEffect(() => {
    if (tokenid) clearInterval(tokenid);
    tokenid = setInterval(function () {
      if (!loadtoken) fetchTokenData();
    }, 500);
  }, [account]);

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then((msg) => {
        if (msg.type === "error") {
          setNotification(msg);
        }
      });
    } else {
      // then user DOES NOT have a wallet
    }

    // We want to ensure that we are storing the UTM parameters for later, even if the user follows links
  }, []);

  return (
    <Router>
      <StyledContainer className="section-background mint sticky">
        <TopBar setNotification={setNotification} />

        <Switch>
          <Route exact path="/farms">
            <Landing
              setNotification={setNotification}
              farms={[flexinfo, locks[0], locks[1]]}
              price={price.price}
              tokenprice={price.farmprice}
              totalStaked={
                (flexinfo.totalStaked ? flexinfo.totalStaked / 1 : 0) +
                (locks[0].totalStaked ? locks[0].totalStaked / 1 : 0) +
                (locks[1].totalStaked ? locks[1].totalStaked / 1 : 0)
              }
            />
          </Route>
          <Route exact path="/liquidity">
            <Liquidity setNotification={setNotification} />
          </Route>
          <Route exact path="/">
            <Redirect to={"/farms"} />
          </Route>
        </Switch>
        <Notification data={notification} />
        {/* <Footer
          price={price.price}
          tokenprice={price.farmprice}
          totalStaked={
            (flexinfo.totalStaked ? flexinfo.totalStaked / 1 : 0) +
            (locks[0].totalStaked ? locks[0].totalStaked / 1 : 0) +
            (locks[1].totalStaked ? locks[1].totalStaked / 1 : 0)
          }
        /> */}
      </StyledContainer>
    </Router>
  );
}

const StyledContainer = styled(Box)``;
export default App;
