import React, { useEffect, useState } from 'react';
import { useAddress } from "./web3Context";
import { multicall, } from '../utils/contracts';
import ERC20ABI from '../abis/ERC20ABI.json'
import { MULTICALL_ADDR, PAIR_ADDR, ROUTER_ADDR, TOKEN_ADDR } from 'src/abis/address';
import MulticallABI from '../abis/MultiCallABI.json'
import PairABI from '../abis/PancakePairABI.json';
import { ethers } from 'ethers';

const defaultVal = {
    bunnyallow: false,
    ethbalance: 0,
    bunnybalance: 0,
    reserves: { reserve0: 0, reserve1: 0 }
}

export const LiquidityDataContext = React.createContext(defaultVal)

export default function useLiquidityData() {
    return React.useContext(LiquidityDataContext);
}

let liquidityid = null, loadliquidity = false;
export function LiquidityDataProvider({ children }) {

    const account = useAddress();
    const [bunnyallow, setBunnyAllow] = useState(false);
    const [ethbalance, setEthBalance] = useState(0);
    const [bunnybalance, setBunnyBalance] = useState(0);
    const [reserves, setReserves] = useState({ reserve0: 0, reserve1: 0 })

    async function fetchLiquidityData() {
        try {
            let calls = [{
                address: MULTICALL_ADDR,
                name: 'getEthBalance',
                params: [account]
            }]
            multicall(MulticallABI, calls).then(result => {
                setEthBalance(result[0][0]);
            });
            calls = [{
                address: TOKEN_ADDR,
                name: 'allowance',
                params: [account, ROUTER_ADDR]
            }]
            calls.push({
                address: TOKEN_ADDR,
                name: 'balanceOf',
                params: [account]
            })
            multicall(ERC20ABI, calls).then(result => {
                setBunnyBalance(result[1][0]);
                setBunnyAllow(result[0][0] > ethers.utils.parseEther('10000'));
            })
            calls = [{
                address: PAIR_ADDR,
                name: 'getReserves',
                params: []
            }];
            multicall(PairABI, calls).then(result => {
                setReserves({ reserve0: result[0][0], reserve1: result[0][1] });
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!account) return;
        fetchLiquidityData();
        if (liquidityid) clearInterval(liquidityid)
        liquidityid = setInterval(function () {
            if (!loadliquidity)
                fetchLiquidityData();
        }, 5000)
    }, [account])

    return <LiquidityDataContext.Provider value={{ bunnyallow, ethbalance, bunnybalance, reserves }} children={children} />;
}