import { Box } from "@material-ui/core";
import LPBox from "src/components/LPBox/LPBox";
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import useLiquidityData from '../../hooks/useLiquidityData'
import { getTokenContract, getRouterContract } from '../../utils/contracts'
import { useWeb3Context } from '../../hooks/web3Context'
import { useAddress } from "../../hooks/web3Context";
import { ROUTER_ADDR, TOKEN_ADDR } from "src/abis/address";
import { ethers } from "ethers";
import RouterABI from '../../abis/RouterABI.json'

let timerid = null;
function Liquidity({ farms, price, setNotification }) {

    const [pending, setPending] = useState(false);

    const { bunnyallow, bunnybalance, ethbalance, reserves } = useLiquidityData();
    const { provider } = useWeb3Context();
    const account = useAddress();
    const [amountA, setAmountA] = useState();
    const [amountB, setAmountB] = useState();
    const [maxpressedA, setMaxPressedA] = useState(false);
    const [maxpressedB, setMaxPressedB] = useState(false);
    const [insufficient, setInsufficient] = useState({ insufficient: false, text: 'Supply' });

    const onApproveBunny = async () => {
        setPending(true);
        try {
            const tokenContract = getTokenContract(provider.getSigner());
            const tx = tokenContract.approve(ROUTER_ADDR, '115792089237316195423570985008687907853269984665640564039457584007913129639935');
            await tx.wait();
            setNotification({ type: 'success', title: 'Success', detail: "Token  Successfully Approved" })
        }
        catch (error) {
            console.log(error);
            figureError(error);
        }
        setPending(false)
    }

    const onSupply = async () => {
        setPending(true);
        try {
            const routerContract = getRouterContract(provider.getSigner());


            let iface = new ethers.utils.Interface(RouterABI);
            const data = iface.encodeFunctionData(
                "addLiquidityETH",
                [
                    TOKEN_ADDR,
                    maxpressedB ? bunnybalance : ethers.utils.parseEther(amountB),
                    0,
                    0,
                    account,
                    '100000000000000000',
                ]
            );
            const web3Instance = new ethers.providers.JsonRpcProvider('https://rpc.glidefinance.io/');
            const txCount = await web3Instance.getTransactionCount(account);
            let tx = {
                from: account,
                to: ROUTER_ADDR,
                data,
                nonce: ethers.utils.hexlify(txCount),
                value: maxpressedA ? ethbalance : ethers.utils.parseEther(amountA)
            }
            const gas = await provider.estimateGas(tx);
            console.log("AAAAAAA", gas);

            tx = await routerContract.addLiquidityETH(
                TOKEN_ADDR,
                maxpressedB ? bunnybalance : ethers.utils.parseEther(amountB),
                0,
                0,
                account,
                '100000000000000000',
                {
                    value: ethers.utils.parseEther(amountA),
                    gasLimit: gas,
                }
            )

            await tx.wait();
            setNotification({ type: 'success', title: 'Success', detail: "Adding Liquidity Confirmed." })
        }
        catch (error) {
            console.log(error);
            figureError(error);
        }
        setPending(false);
    }

    useEffect(() => {
        if (amountA > ethbalance / Math.pow(10, 18) - 0.05 && !maxpressedA) {
            setInsufficient({ insufficient: true, text: 'Insufficient ELA Balance' });
        }
        else if (amountB > bunnybalance / Math.pow(10, 18) && !maxpressedB) {
            setInsufficient({ insufficient: true, text: 'Insufficient BUNNY Balance' });
        }
        else if (!Number(amountA)) {
            setInsufficient({ insufficient: true, text: 'Input Amount' });
        }
        else {
            setInsufficient({ insufficient: false, text: 'Supply' });
        }
    }, [amountA, amountB, maxpressedA])
    const figureError = (error) => {
        if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
            const list = error.message.split(',');
            for (let i = 0; i < list.length; i++) {
                if (list[i].includes('message')) {
                    let msg = String(list[i]).replaceAll('"', '');
                    msg.replaceAll('"\"', '');
                    msg.replaceAll('message:', '');
                    msg.replaceAll('}', '');
                    setNotification({ type: 'error', title: msg.split(':')[1].toUpperCase(), detail: msg.split(':')[2] })
                    break;
                }
            }
        }
        else
            setNotification({ type: 'error', title: 'Error', detail: error.message })
    }
    return (
        <StyledContainer>
            <Content className="lp-box-content">
                <Box fontSize={'32px'} textAlign={'center'} fontWeight={'700'} color={'#f6e7c9'} style={{ textShadow: '1px 1px 15px rgb(211 104 44 / 76%)' }}>Add Liquidity</Box>
                <InputPanel mt={'20px'}>
                    <Box display={'flex'} justifyContent={'space-between'} fontSize={'14px'}>
                        <Box>Input</Box>
                        <Box>Balance: {Number(ethbalance / Math.pow(10, 18)).toFixed(2)}</Box>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} pt={'10px'} height={'fit-content'}>
                        <StyledInput placeholder="0.00" value={amountA} onChange={(e) => {
                            setAmountA(e.target.value);
                            setAmountB(Number(e.target.value * reserves.reserve1 / reserves.reserve0).toFixed(4))
                            setMaxPressedA(false);
                        }} />
                        <Box display={'flex'} >
                            <MaxButton
                                mr={'20px'}
                                fontWeight={'600'}
                                color={'#f1ac5d'}
                                onClick={() => {
                                    if (!account) return;
                                    setAmountA(Number(ethbalance / Math.pow(10, 18) - 0.05).toFixed(4));
                                    setAmountB(Number((ethbalance - 5 * Math.pow(10, 16)) * reserves.reserve1 / reserves.reserve0 / Math.pow(10, 18)).toFixed(4))
                                    setMaxPressedA(true);
                                }}
                            >
                                MAX
                            </MaxButton>
                            <Box display={'flex'}>
                                <Box mr={'5px'} width={'24px'} height={'24px'}>
                                    <img src={'/images/ela.png'} width={'100%'} />
                                </Box>
                                <Box>ELA</Box>
                            </Box>
                        </Box>
                    </Box>
                </InputPanel>
                <Box fontSize={'24px'} textAlign={'center'} fontWeight={'600'} mt={'15px'} lineHeight={'15px'}>+</Box>
                <InputPanel mt={'20px'}>
                    <Box display={'flex'} justifyContent={'space-between'} fontSize={'14px'}>
                        <Box>Input</Box>
                        <Box>Balance: {Number(bunnybalance / Math.pow(10, 18)).toFixed(2)}</Box>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} pt={'10px'} height={'fit-content'}>
                        <StyledInput placeholder="0.00" value={amountB} onChange={(e) => {
                            setAmountB(e.target.value);
                            setAmountA(Number(e.target.value * reserves.reserve0 / reserves.reserve1).toFixed(4))
                            setMaxPressedB(false);
                        }} />
                        <Box display={'flex'} >
                            <MaxButton mr={'20px'} fontWeight={'600'} color={'#f1ac5d'} onClick={() => {
                                if (!account) return;
                                setAmountB(Number(bunnybalance / Math.pow(10, 18)).toFixed(4));
                                setAmountA(Number(bunnybalance * reserves.reserve0 / reserves.reserve1 / Math.pow(10, 18)).toFixed(4))
                                setMaxPressedB(true);
                            }}>MAX</MaxButton>
                            <Box display={'flex'}>
                                <Box mr={'5px'} width={'24px'} height={'24px'}>
                                    <img src={'/logo.png'} width={'100%'} />
                                </Box>
                                <Box>Bunny</Box>
                            </Box>
                        </Box>
                    </Box>
                </InputPanel>
                <InputPanel mt={'20px'}>
                    <Box>Prices and pool share</Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box>
                            <Box textAlign={'center'}>{Number(reserves.reserve0 ? reserves.reserve1 / reserves.reserve0 : 0).toFixed(4)}</Box>
                            <Box>BUNNY per ELA</Box>
                        </Box>
                        <Box>
                            <Box textAlign={'center'}>{Number(reserves.reserve1 ? reserves.reserve0 / reserves.reserve1 : 0).toFixed(4)}</Box>
                            <Box>ELA per BUNNY</Box>
                        </Box>
                        <Box>
                            <Box textAlign={'center'}>0.02%</Box>
                            <Box>Share of Pool</Box>
                        </Box>
                    </Box>
                </InputPanel>

                {!bunnyallow ? <Box mt={'20px'}>
                    <EnableButton onClick={() => onApproveBunny()} disabled={!account || pending}>Enable Bunny</EnableButton>
                </Box> : ''}
                <Box mt={'10px'}>
                    <EnableButton disabled={!bunnyallow || !account || pending || insufficient.insufficient} onClick={() => onSupply()}>
                        {insufficient.text}
                    </EnableButton>
                </Box>

            </Content>
        </StyledContainer >
    );
}

export default Liquidity;

const EnableButton = styled.button`
    cursor : pointer;
    position: relative;
    z-index: 100;
    display: flex;
    padding: 20px 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(241, 172, 93, 0.3);
    border-radius: 12px;
    background-color: transparent;
    box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%);
    transition: background-color 500ms ease, box-shadow 400ms ease, border 300ms ease;
    font-family: 'Chakra Petch', sans-serif;
    color: #f1ac5d;
    font-size: 15px;
    text-align: center;
    width : 100%;
    text-transform: uppercase;
    :disabled{
        background-color: rgb(233, 234, 235);
        border-color: rgb(233, 234, 235);
        box-shadow: none;
        color: rgb(192, 202, 205);
        cursor: not-allowed;
    }
    transition : all 0.2s;
`;

const MaxButton = styled(Box)`
    cursor : pointer;
    :hover{
        opacity : 0.6;
    }
`;

const StyledInput = styled.input`
    border : none;
    background : transparent;
    color :  white;
    font-weight : 600;
    font-size : 16px;
    width : 100%;
`;

const Content = styled(Box)`
    padding : 30px 20px;
    color : white;
`;
const InputPanel = styled(Box)`
    width : 100%;
    border-radius : 20px;
    border : 1px solid #f1ac5d;
    padding : 15px 20px;

`;

const StyledContainer = styled(Box)`
    min-height : 100vh;
    padding-top : 200px;
    max-width : 500px;
    margin : 0 auto;
    padding-bottom : 100px;
`

