import { Box } from "@material-ui/core";
import { useWeb3Context } from "../../hooks/web3Context";

import styled from "styled-components";
import { ethers } from "ethers";

import "./LPBox.scss";
import ConnectMenu from "../TopBar/ConnectMenu";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineSwapVert } from "react-icons/md";
import { FiArrowDown } from "react-icons/fi";
import { BsFillPencilFill } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { BiLinkExternal } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import card from "../../assets/images/Card.png";
import {
  getPairContract,
  getFlexibleContract,
  getTokenContract,
  getLockContract,
} from "../../utils/contracts";
import { FLEXIBLE_ADDR, LOCK_ADDR } from "src/abis/address";

const customStyles = {
  content: {
    top: "calc(50% )",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxHeight: "100vh",
    width: "100%",
    maxWidth: "345px",
    transform: "translate(-50%, -50%)",
    fontFamily: "Kanit",
    borderRadius: "12px",
    background:
      "linear-gradient(135deg, rgb(0 0 0 / 62%) 0%, rgb(50 33 33) 50%, rgb(0 0 0 / 61%) 75%)",
    border: "none",
    boxShadow:
      "8px 8px 24px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 0 1px 1px rgb(9 13 20 / 40%)",
    padding: "0",
  },
};

const customStyles1 = {
  content: {
    top: "calc(50% )",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxHeight: "100vh",
    width: "100%",
    maxWidth: "357px",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    background:
      "linear-gradient(135deg, rgb(0 0 0 / 62%) 0%, rgb(50 33 33) 50%, rgb(0 0 0 / 61%) 75%)",
    border: "none",
    boxShadow:
      "8px 8px 24px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 0 1px 1px rgb(9 13 20 / 40%)",
    padding: "0",
  },
};

function LPBox({ duration, farms, index, setNotification }) {
  const [show_details, setsShowDetails] = useState(false);
  const [showdetail, setShowDetail] = useState(false);
  const { connect, disconnect, connected, web3, chainID, provider } =
    useWeb3Context();
  const [modalopen, setModalOpen] = useState(false);
  const [calcshowtype, setCalcShowType] = useState(false);
  const [day, setDay] = useState(1);
  const [pending, setPending] = useState(false);
  const [calcamount, setCalcAmount] = useState(0);

  const [modaldata, setModalData] = useState({ isStake: true, balance: 0 });
  const [amount, setAmount] = useState("0");
  const [open, setOpen] = useState(false);
  const [insufficient, setInsufficient] = useState(false);
  const [maxpressed, setMaxPressed] = useState(false);

  const inputNumberFormat = (str) => {
    return str;
  };
  const onApproveContract = async () => {
    setPending(true);
    try {
      const lpTokenContract = getPairContract(provider.getSigner());
      const tx = await lpTokenContract.approve(
        farms[index].address,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      );
      await tx.wait();
      setNotification({});
      // fetchData();
      setNotification({
        type: "success",
        title: "Success",
        detail: "Token  Successfully Approved",
      });
    } catch (error) {
      figureError(error);
    }
    setPending(false);
  };

  const onConfirm = async () => {
    setPending(true);
    try {
      let tx, contract;
      if (index === 0) {
        contract = getFlexibleContract(provider.getSigner());
      } else {
        contract = getLockContract(provider.getSigner());
      }
      if (modaldata.isStake) {
        tx = await contract.deposit(
          index === 0 ? 0 : index - 1,
          maxpressed
            ? farms[index].balance
            : ethers.utils.parseEther(amount.toString())
        );
      } else
        tx = await contract.withdraw(
          index === 0 ? 0 : index - 1,
          maxpressed
            ? index === 0
              ? farms[index].stakedAmount
              : farms[index].withdrawableAmount
            : ethers.utils.parseEther(amount.toString())
        );
      await tx.wait();
      setNotification({
        type: "success",
        title: "Success",
        detail: "Transaction Successfully Excuted",
      });
      // fetchData();
    } catch (error) {
      figureError(error);
    }
    setPending(false);
  };

  const onHarvest = async () => {
    setPending(true);
    try {
      // let tokenContract = getTokenContract(provider.getSigner());
      // const tt = await tokenContract.approve(LOCK_ADDR, '115792089237316195423570985008687907853269984665640564039457584007913129639935');
      // await tt.wait();
      let tx, contract;
      if (index === 0) {
        contract = getFlexibleContract(provider.getSigner());
        tx = await contract.deposit(0, 0, {
          value: farms[index].performanceFee,
        });
      } else {
        contract = getLockContract(provider.getSigner());
        tx = await contract.claim(index - 1, {
          value: farms[index].performanceFee,
        });
      }
      await tx.wait();
      setNotification({
        type: "success",
        title: "Success",
        detail: "Transaction Successfully Excuted",
      });
      // fetchData();
    } catch (error) {
      figureError(error);
    }
    setPending(false);
  };

  useEffect(() => {
    if (amount / 1 < 0 || amount / 1 > modaldata.balance) setInsufficient(true);
    else setInsufficient(false);
  }, [amount]);

  const figureError = (error) => {
    if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
      const list = error.message.split(",");
      for (let i = 0; i < list.length; i++) {
        if (list[i].includes("message")) {
          let msg = String(list[i]).replaceAll('"', "");
          msg.replaceAll('""', "");
          msg.replaceAll("message:", "");
          msg.replaceAll("}", "");
          setNotification({
            type: "error",
            title: msg.split(":")[1].toUpperCase(),
            detail: msg.split(":")[2],
          });
          break;
        }
      }
    } else
      setNotification({ type: "error", title: "Error", detail: error.message });
  };
  return (
    <Box className="lp-box">
      <img src={card} className="card" />
      <Modal
        isOpen={modalopen > 0}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ModalHeader>
          <Box className="staked-s-2" fontSize={"20px"} fontWeight={"600"}>
            ROI Calculator
          </Box>
          <Box
            height={"48px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => setModalOpen(false)}
          >
            <AiOutlineClose
              color={"#f6e7c9 "}
              fontSize={"18px"}
              cursor={"pointer"}
            />
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box className="staked-s-2" fontWeight={600} fontSize={"12px"}>
            BUNNY-WELA STAKED
          </Box>
          <InputPanel>
            <Box width={"100%"} mr={"15px"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <CustomInput
                  className="none-txt"
                  type="number"
                  style={{ width: calcshowtype ? "100px" : "200px" }}
                  onKeyPress={(event) => {
                    if (event?.key === "-" || event?.key === "+") {
                      event.preventDefault();
                    }
                  }}
                  value={calcamount}
                  onChange={(event) => {
                    setCalcAmount(inputNumberFormat(event.target.value));
                  }}
                />
                <Box
                  className="lp-box-roi-txt-details2"
                  fontSize={"16px"}
                  width={calcshowtype ? "120px" : "unset"}
                >
                  {!calcshowtype ? "USD" : "BUNNY-WELA"}
                </Box>
              </Box>
              <Box
                className="lp-box-roi-txt-details2"
                fontSize={"12px"}
                textAlign={"right"}
              >
                {Number(
                  farms[index].price
                    ? calcshowtype
                      ? calcamount * farms[index].price
                      : calcamount / farms[index].price
                    : 0
                ).toFixed(6)}{" "}
                {calcshowtype ? "USD" : "BUNNY-WELA"}
              </Box>
            </Box>
            <MdOutlineSwapVert
              color={"#f6e7c9"}
              fontSize={"24px"}
              lineHeight={"24px"}
              onClick={() => setCalcShowType(!calcshowtype)}
              style={{ cursor: "pointer" }}
            />
          </InputPanel>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box width={"25%"} height={"20px"}>
              <StyledButton
                onClick={() =>
                  !calcshowtype
                    ? setCalcAmount(100)
                    : setCalcAmount(100 / farms[index].price)
                }
              >
                $100
              </StyledButton>
            </Box>
            <Box width={"25%"} height={"20px"}>
              <StyledButton
                onClick={() =>
                  !calcshowtype
                    ? setCalcAmount(1000)
                    : setCalcAmount(1000 / farms[index].price)
                }
              >
                $1000
              </StyledButton>
            </Box>
            <Box width={"45%!important"} height={"20px"}>
              <StyledButton
                onClick={() =>
                  !calcshowtype
                    ? setCalcAmount(
                        (farms[index].balance / Math.pow(10, 18)) *
                          farms[index].price
                      )
                    : setCalcAmount(farms[index].balance / Math.pow(10, 18))
                }
              >
                MY BALANCE
              </StyledButton>
            </Box>
          </Box>
          <Box
            className="staked-s-2"
            fontWeight={600}
            fontSize={"12px"}
            mt={"24px"}
          >
            STAKED FOR
          </Box>
          <DaySelectPanel mt={"10px"} active={day}>
            <Box width={"20%"} onClick={() => setDay(0)}>
              FLEXIBLE
            </Box>
            <Box width={"20%"} onClick={() => setDay(1)}>
              7D
            </Box>
            <Box width={"20%"} onClick={() => setDay(2)}>
              15D
            </Box>
          </DaySelectPanel>
          <Box display={"flex"} justifyContent={"center"} my={"24px"}>
            <FiArrowDown color={"#f6e7c9"} fontSize={"24px"} />
          </Box>
          <ROIPanel>
            <Box>
              <Box className="staked-s-2" fontSize={"12px"} fontWeight={"600"}>
                ROI AT CURRENT RATES
              </Box>
              <Box
                className="staked-s-2"
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                fontSize={"24px"}
                fontWeight={"600"}
                mt={"4px"}
              >
                <Box>
                  $
                  {farms
                    ? Number(
                        calcshowtype
                          ? (farms[day].rate / 100) * calcamount
                          : (farms[day].rate / 100) * calcamount
                      ).toFixed(3)
                    : 0}
                </Box>
                <BsFillPencilFill
                  color={"#f6e7c9"}
                  fontSize={"20px"}
                  cursor={"pointer"}
                />
              </Box>
              <Box color={"#ffffff"} fontSize={"12px"}>
                {farms && farms[day].tokenprice
                  ? Number(
                      calcshowtype
                        ? ((farms[day].rate / 100) * calcamount) /
                            farms[day].tokenprice
                        : ((farms[day].rate / 100) * calcamount) /
                            farms[day].tokenprice
                    ).toFixed(3)
                  : 0}{" "}
                Bunny
              </Box>
            </Box>
          </ROIPanel>
        </ModalBody>
        <Detail>
          <Box
            className="lp-box-roi-txt-details"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ cursor: "pointer" }}
            onClick={() => setShowDetail(!showdetail)}
          >
            <Box mr={"16px"} fontSize={"16px"} fontWeight={"600"}>
              Details
            </Box>
            <FaChevronDown fontSize={"14px"} />
          </Box>
          <DetailBody active={showdetail}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              color={"#ffffff"}
              fontSize={"14px"}
              mt={"15px"}
            >
              <Box>APR (incl. LP rewards)</Box>
              <Box> {Number(farms[day].rate).toFixed(2)}%</Box>
            </Box>
            <Box color={"#ffffff"} fontSize={"12px"} mt={"35px"}>
              <Box>- Calculated based on current rates.</Box>
              <Box>
                - All figures are estimates provided for your convenience only,
                and by no means represent guaranteed returns..
              </Box>
            </Box>
            <a
              href={
                "https://glidefinance.io/info/token/0x75740fc7058da148752ef8a9adfb73966deb42a8"
              }
              target={"_blank"}
              style={{ textDecoration: "none", color: "#f1ac5d" }}
            >
              <Box
                fontWeight={600}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={"15px"}
              >
                <Box mr={"8px"}>Get BUNNY-WELA</Box>
                <BiLinkExternal fontSize={"18px"} />
              </Box>
            </a>
          </DetailBody>
        </Detail>
      </Modal>

      <Modal
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
        style={customStyles1}
        contentLabel="Example Modal"
      >
        <ModalHeader>
          <Box
            className="text--shadow"
            color={"#f6e7c9"}
            background={"transparent"}
            fontSize={"20px"}
            fontWeight={"600"}
          >
            {modaldata.isStake ? "Stake Tokens" : "Withdraw Tokens"}
          </Box>
          <Box
            height={"48px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => setOpen(false)}
          >
            <AiOutlineClose
              color={"#f6e7c9"}
              fontSize={"18px"}
              cursor={"pointer"}
            />
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box
            className="modal-txt-box"
            display={"flex"}
            justifyContent={"end"}
            mb={"5px"}
            color={"rgb(85, 176, 221)"}
            fontSize={"12px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setAmount(modaldata.balance);
              setMaxPressed(true);
            }}
          >
            {modaldata.isStake ? "Balance" : "Withdrawable Amount"} :{" "}
            {modaldata.balance.toFixed(3)} <span className="max-btn">MAX</span>
          </Box>
          <Box
            bgcolor={"transparent"}
            color={"#f1ac5d"}
            border="1px solid #f6e7c9"
            padding={"3px 10px"}
            borderRadius={"8px"}
          >
            <CustomInput
              className="amountinput"
              type="number"
              value={amount.toString()}
              style={{ textAlign: "left" }}
              onKeyPress={(event) => {
                if (event?.key === "-" || event?.key === "+") {
                  event.preventDefault();
                }
              }}
              onChange={(event) => {
                setMaxPressed(false);
                setAmount(inputNumberFormat(event.target.value));
              }}
            />
          </Box>
          {insufficient ? (
            <Box color={"tomato"} display={"flex"} justifyContent={"end"}>
              Insufficient Balance
            </Box>
          ) : (
            ""
          )}
        </ModalBody>
        <ModalActions className="modal-center-s">
          <Box height={"48px"} width={"49%"}>
            <EnableButton
              className="cancel-btn-1"
              onClick={() => {
                setOpen(false);
              }}
              style={{ fontSize: "16px", borderRadius: "5px" }}
            >
              Cancel
            </EnableButton>
          </Box>
          <Box height={"48px"} width={"49%"}>
            <EnableButton
              className="cancel-btn-1"
              disabled={insufficient || !Number(amount)}
              onClick={() => onConfirm()}
              style={{ fontSize: "16px", borderRadius: "5px" }}
            >
              Confirm
            </EnableButton>
          </Box>
        </ModalActions>
      </Modal>

      <Box className="lp-box-content">
        <Box className="lp-box-content-top">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={"12px"}
          >
            <Box className="image-content">
              <img
                className="ela-svg-style"
                src={"./images/elastos.svg"}
                width="40"
                height="40"
              />
              <img src={"./logo.png"} width="50" height="50" />
            </Box>
            <Box className="token-name">BUNNY-WELA</Box>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box className={"attr_title"}>APR:</Box>
            {farms[index].rate ? (
              <Box
                className={"attr_cont"}
                display={"flex"}
                alignItems={"center"}
              >
                {Number(farms[index].rate).toFixed(2)} %
                <Box
                  className="percentage_But"
                  onClick={() => setModalOpen(true)}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="18px"
                    color="text"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sc-bdvvtL cQhkZ"
                  >
                    <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z"></path>
                    <path d="M11.25 7.72H6.25V9.22H11.25V7.72Z"></path>
                    <path d="M18 15.75H13V17.25H18V15.75Z"></path>
                    <path d="M18 13.25H13V14.75H18V13.25Z"></path>
                    <path d="M8 18H9.5V16H11.5V14.5H9.5V12.5H8V14.5H6V16H8V18Z"></path>
                    <path d="M14.09 10.95L15.5 9.54L16.91 10.95L17.97 9.89L16.56 8.47L17.97 7.06L16.91 6L15.5 7.41L14.09 6L13.03 7.06L14.44 8.47L13.03 9.89L14.09 10.95Z"></path>
                  </svg>
                </Box>
              </Box>
            ) : (
              <Skeleton
                variant={"text"}
                width={"80px"}
                style={{ transform: "unset", marginBottom: "3px" }}
              />
            )}
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box className={"attr_title"}>Earn:</Box>
            <Box className={"attr_cont"} display={"flex"} alignItems={"center"}>
              Bunny
            </Box>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"0.75rem"}
          >
            <Box className={"attr_title"}>Withdraw Lock:</Box>
            <Box
              className={"lock_amount"}
              display={"flex"}
              alignItems={"center"}
            >
              <svg
                viewBox="0 0 330 330"
                width="12px"
                color="secondary"
                xmlns="http://www.w3.org/2000/svg"
                class="key"
              >
                <path d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80.001,38.131,80.001,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M110.001,85 c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v45H110.001V85z"></path>
              </svg>
              {duration}
            </Box>
          </Box>

          <Box paddingTop={"16px"}>
            <Box display={"flex"} alignItems={"center"}>
              <span className={"attr_title_s_l"}>{"bunny"}</span>
              <span className={"attr_title_s_d"}>{" earned"}</span>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              height={"65px"}
              marginBottom={"8px"}
            >
              <span className={"attr_lock_amount"}>
                {farms[index].pendingRewards !== undefined ? (
                  Number(farms[index].pendingRewards).toFixed(3)
                ) : (
                  <Skeleton
                    variant={"text"}
                    width={"120px"}
                    style={{ transform: "unset", marginBottom: "3px" }}
                  />
                )}
              </span>
              <Box width={"108px"} height={"48px"}>
                <EnableButton
                  className={"attr_lock_but harvest-btn-1"}
                  disabled={
                    !farms[index] ||
                    (index === 0
                      ? !Number(farms[index].pendingRewards)
                      : !Number(farms[index].claimableReward)) ||
                    pending
                  }
                  onClick={() => onHarvest()}
                >
                  Harvest
                </EnableButton>
              </Box>
            </Box>

            <Box display={"flex"} alignItems={"center"} paddingBottom={"10px"}>
              <span className={"attr_title_s_l"}>{"BUNNY-WELA"}</span>
              <span className={"attr_title_s_d"}>{" staked"}</span>
              <a
                className="add-lp-btn-2"
                href="https://glidefinance.io/info/token/0x75740fc7058da148752ef8a9adfb73966deb42a8"
                target={"_blank"}
              >
                {"Add LP"}
                <svg
                  viewBox="0 0 24 24"
                  color="primary"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sc-bdvvtL golKGe svg-btn"
                >
                  <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path>
                </svg>
              </a>
            </Box>

            {!connected ? (
              <Box marginTop={"8px"} className="connect-button">
                <ConnectMenu bigType={true} setNotification={setNotification} />
              </Box>
            ) : (
              ""
            )}
            {connected &&
            ((farms[index] && !farms[index].allowance) || !farms[index]) ? (
              <Box width={"100%"} height={"48px"} marginTop={"8px"}>
                <EnableButton
                  className="Enable-btn"
                  onClick={() => onApproveContract()}
                  disabled={pending}
                >
                  Enable Contract
                </EnableButton>
              </Box>
            ) : (
              ""
            )}
            {connected &&
            farms[index] &&
            farms[index].allowance &&
            !farms[index].stakedAmount ? (
              <Box width={"100%"} height={"48px"} marginTop={"8px"}>
                <EnableButton
                  disabled={pending}
                  onClick={() => {
                    setOpen(true);
                    setModalData({
                      isStake: true,
                      balance: Number(farms[index].balance / Math.pow(10, 18)),
                    });
                  }}
                >
                  Stake
                </EnableButton>
              </Box>
            ) : (
              ""
            )}
            {connected &&
            farms[index] &&
            farms[index].allowance &&
            farms[index].stakedAmount ? (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <span className={"attr_lock_amount"}>
                  {(farms[index].stakedAmount / Math.pow(10, 18)).toFixed(3)}
                </span>
                <Box display={"flex"} mt={"8px"}>
                  <Box width={"100%"} height={"48px"} mr={"5px"}>
                    <StakeAction
                      className="plus-icon"
                      disabled={pending}
                      style={{ lineHeight: "10px" }}
                      onClick={() => {
                        setOpen(true);
                        setModalData({
                          isStake: true,
                          balance: Number(
                            farms[index].balance / Math.pow(10, 18)
                          ),
                        });
                      }}
                    >
                      +
                    </StakeAction>
                  </Box>
                  <Box width={"100%"} height={"48px"}>
                    <StakeAction
                      className="minus-icon"
                      disabled={pending}
                      style={{ lineHeight: "10px" }}
                      onClick={() => {
                        setOpen(true);
                        setModalData({
                          isStake: false,
                          balance: Number(
                            (index === 0
                              ? farms[index].stakedAmount
                              : farms[index].withdrawableAmount) /
                              Math.pow(10, 18)
                          ),
                        });
                      }}
                    >
                      -
                    </StakeAction>
                  </Box>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Box className="lp-box-content-bottom">
          <DetailsBox
            showType={show_details}
            className={"lp-box-details-text"}
            onClick={() => setsShowDetails(!show_details)}
          >
            {show_details && (
              <Box paddingBottom={"5px"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box className={"attr_text"}>Total BUNNY-WELA Staked:</Box>
                  <Box className={"attr_text"}>
                    {farms[index].totalStaked
                      ? Number(farms[index].totalStaked).toFixed(5)
                      : "0.00000"}
                  </Box>
                </Box>
                {/* <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Box className={'attr_text'}>Total liquidity:</Box>
                                    <Box className={'attr_text'}>${farms[index].totalLiquidity ? farms[index].totalLiquidity.toFixed(2) : '0.00'}</Box>
                                </Box> */}
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box className={"attr_text"}>Deposit Fee:</Box>
                  <Box className={"attr_text"}>
                    {farms[index].depositFee
                      ? Number(farms[index].depositFee).toFixed(2)
                      : "0.00"}
                    %
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box className={"attr_text"}>Withdraw Fee:</Box>
                  <Box className={"attr_text"}>
                    {farms[index].withdrawFee
                      ? Number(farms[index].withdrawFee).toFixed(2)
                      : "0.00"}
                    %
                  </Box>
                </Box>
                <Box marginTop={"1rem"}>
                  <Box className={"attr_view"}>
                    <a
                      href="https://glidefinance.io/info/token/0x75740fc7058da148752ef8a9adfb73966deb42a8"
                      target={"_blank"}
                    >
                      {"ADD BUNNY-WELA"}
                      <svg
                        viewBox="0 0 24 24"
                        color="primary"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        class="sc-bdvvtL golKGe"
                      >
                        <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path>
                      </svg>
                    </a>
                  </Box>

                  <Box className={"attr_view"}>
                    <a
                      href={
                        "https://eth.elastos.io/address/0x2f45C6Df50E7F8517dAc64943a2161bBCFBa1182/transactions"
                      }
                      target={"_blank"}
                    >
                      {"View Contract"}
                      <svg
                        viewBox="0 0 24 24"
                        color="primary"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        class="sc-bdvvtL golKGe"
                      >
                        <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path>
                      </svg>
                    </a>
                  </Box>

                  <Box className={"attr_view"}>
                    <a
                      href={
                        "https://glidefinance.io/info/pool/0x2f45c6df50e7f8517dac64943a2161bbcfba1182"
                      }
                      target={"_blank"}
                    >
                      {"See Pair Info"}
                      <svg
                        viewBox="0 0 24 24"
                        color="primary"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        class="sc-bdvvtL golKGe"
                      >
                        <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path>
                      </svg>
                    </a>
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              paddingBottom="10px"
            >
              <Box>{show_details ? "Hide" : "Details"}</Box>

              <div
                style={{
                  transform: show_details ? " rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  color="text"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sc-bdvvtL cQhkZ arrow-icon"
                >
                  <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
                </svg>
              </div>
            </Box>
          </DetailsBox>
        </Box>
      </Box>
    </Box>
  );
}

const EnableButton = styled.button`
    position: relative;
    z-index: 100;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(241, 172, 93, 0.3);
    border-radius: 12px;
    background-color: transparent;
    box-shadow: inset 0 0 6px 0 rgb(241 172 93 / 30%);
    transition: background-color 500ms ease, box-shadow 400ms ease, border 300ms ease;
    font-family: 'Chakra Petch', sans-serif;
    color: #f1ac5d;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    }
            :disabled{
                background-color: rgb(233, 234, 235);
            border-color: rgb(233, 234, 235);
            box-shadow: none;
            color: rgb(192, 202, 205);
            cursor: not-allowed;
    }
            transition : all 0.2s;
`;

const Detail = styled(Box)`
  padding: 24px;
  background: transparent;
`;

const DetailBody = styled(Box)`
  height: ${({ active }) => (active ? "180px" : "0")};
  overflow: hidden;
  transition: all 0.3s;
`;

const StyledButton = styled(Box)`
            border: 0px;
            border-radius: 12px;
            cursor: pointer;
            display: inline-flex;
            font-family: inherit;
            font-weight: 600;
            -webkit-box-pack: center;
            justify-content: center;
            align-items : center;
            letter-spacing: 0.03em;
            line-height: 1;
            opacity: 1;
            outline: 0px;
            transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
            height: 100%;
            font-size: 12px;
            box-shadow: none;
            color: #f6e7c9;
    text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
            width: 100%;
            padding: 4px 16px;
            :hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
                opacity: 0.65;
    }
            :disabled{
                background - color: rgb(233, 234, 235);
            border-color: rgb(233, 234, 235);
            box-shadow: none;
            color: rgb(192, 202, 205);
            cursor: not-allowed;
    }
            `;

const InputPanel = styled(Box)`
  background-color: transparent;
  padding: 8px 16px;
  border: 1px solid #f6e7c9;
  display: flex;
  align-items: center;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  border-radius: 12px;
`;

const DetailsBox = styled(Box)`
  > svg {
    ${({ showType }) => (showType ? "transform: rotate(180deg)" : "")};
  }
`;

const ModalHeader = styled(Box)`
  background-image: none;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > svg {
    transition: all 0.2s;
    :hover {
      opacity: 0.8;
    }
  }
`;

const ModalBody = styled(Box)`
  padding: 24px;
`;

const CustomInput = styled.input`
            font-size: 16px !important;
            height : 40px;
            width: 100%;
            border : none!important;
            text-align : right;
            color: #f6e7c9;
            text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
            font-family : 'Kanit';
            background : transparent;
            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit - appearance: none; 
    }
            margin: 0;
            `;
const DaySelectPanel = styled(Box)`
            background-color: transparent;
            border-radius: 12px;
            display: inline-flex;
            border: 1px solid rgb(246, 231, 201);
            width : 100%;
            justify-content : space-between;
            margin : 0!important;
    >div{
                -webkit - box - align: center;
            align-items: center;
            border: 0px;
            border-radius: 12px;
            cursor: pointer;
            display: inline-flex;
            font-family: inherit;
            font-size: 16px;
            font-weight: 600;
            -webkit-box-pack: center;
            justify-content: center;
            letter-spacing: 0.03em;
            line-height: 1;
            opacity: 1;
            outline: 0px;
            transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
            height: 32px;
            padding: 0px 16px;
            color : #f1ac5d;
            width : 33%;
    }
    >div:nth-child(${({ active }) => active + 1}){
                color: #f6e7c9;
    text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
    }
            `;

const ROIPanel = styled(Box)`
  > div {
    height: 120px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid rgb(246, 231, 201);
    background: transparent;
  }
  background: transparent;
  padding: 1px;
  width: 100%;
  border-radius: 12px;
`;

const ModalActions = styled(Box)`
            display : flex;
            justify-content : space-between;
    >button{
                width : 47%;
    }
            @media screen and (max-width : 500px){
                flex - direction : column;
        >button{
                width : 100%;
            margin-bottom : 10px;
        }
    }
            `;

const StakeAction = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 2px solid rgb(255, 180, 43);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: transparent;
  font-size: 24px;
  color: rgb(255, 180, 43);
  cursor: pointer;
`;

export default LPBox;
