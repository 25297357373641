import { Box, Link } from "@material-ui/core";
import "./Landing.scss";
import LPBox from "src/components/LPBox/LPBox";
import header from "../../assets/images/Header.png";
import mobileHeader from "../../assets/images/mob-card.png";
import tabletHeader from "../../assets/images/tablet-card.png";
import logo from "../../assets/images/BunnyLogo.png";
import { useState, useEffect } from "react";
import styled from "styled-components";

let timerid = null;
function Landing({ farms, price, setNotification, tokenprice, totalStaked }) {
  return (
    <div id="landing-view">
      <Box className="title-box">
        <img className="header-card" src={header} />  
        <img className="mob-header-card" src={mobileHeader} />
        <img className="tab-header-card" src={tabletHeader} />
        <Box className="title-box-view">
          <h1 className="h1-txt">FARM</h1>
          <h2 className="h2-txt">Stake LP to earn Bunny.</h2>
        </Box>
        {tokenprice ? (
          <>
            <StyledContainer>
              <Box>
                {/* <SvgBox>
                  {/* <svg
                    viewBox="0 0 772 159"
                    width="130px"
                    color="text"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <image href="/images/logo/logo-horizontal.png"></image>
                  </svg> 
                </SvgBox> */}
                <TokenInfoBox>
                  <ul>
                    <li>Total Value Locked</li>
                    <span>${Number(tokenprice * totalStaked).toFixed(3)}</span>
                  </ul>
                </TokenInfoBox>
                <TokenSellBox>
                  <Box>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      height={"100%"}
                    >
                      <Text>Total Value Locked</Text>
                      <TokenPriceBox>
                        <Link
                          href={"https://glidefinance.io/liquidity"}
                          target={"_blank"}
                        >
                          <img width={"24x"} src={"./logo.png"} />
                          <Box>${Number(price).toFixed(3)}</Box>
                        </Link>
                      </TokenPriceBox>
                    </Box>
                    <TokenBuyBut
                      className="buy-bunny-footer"
                      href={"https://glidefinance.io/swap"}
                      target={"_blank"}
                    >
                      BUY BUNNY
                    </TokenBuyBut>
                  </Box>
                </TokenSellBox>
              </Box>
            </StyledContainer>
            <MobileTokenBuyBut
              className="buy-bunny-footer"
              href={"https://glidefinance.io/swap"}
              target={"_blank"}
            >
              BUY BUNNY
            </MobileTokenBuyBut>
          </>
        ) : (
          <img src={logo} className="logo-header" />
        )}
      </Box>
      <Box className="lp-container">
        <Box className="lp-boxes">
          <LPBox
            duration={"Flexible"}
            farms={farms}
            index={0}
            setNotification={setNotification}
          ></LPBox>
          <LPBox
            duration={"7 Days"}
            farms={farms}
            index={1}
            setNotification={setNotification}
          ></LPBox>
          <LPBox
            duration={"15 Days"}
            farms={farms}
            index={2}
            setNotification={setNotification}
          ></LPBox>
        </Box>
      </Box>
    </div>
  );
}
const StyledContainer = styled(Box)`
  display: flex;
  justify-content: center;
  background: transparent;
  > div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    @media (max-width: 550px) {
      gap: 0px;
      flex-direction: column;
    }
  }
`;

const SvgBox = styled(Box)`
  margin-bottom: 24px;

  @media screen and (min-width: 576px) {
    display: none;
  }

  > svg {
    align-self: center;
    fill: rgb(21, 110, 155);
    flex-shrink: 0;
  }
`;

const TokenInfoBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 576px) {
    order: 1;
    flex-direction: row;
  }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    @media screen and (min-width: 852px) {
      margin-bottom: 0px;
    }
    li {
      color: #f6e7c9;
      text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
      font-weight: 600;
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 8px;
    }
    span {
      color: #fff;
      text-shadow: 1px 1px 15px rgba(211, 104, 44, 0.76);
      font-family: Kanit;
      font-size: 33px !important;
      font-style: normal;
      font-weight: 600;
    }
  }
`;

const TokenSellBox = styled(Box)`
  border-color: rgb(0, 21, 44);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 24px 0px;
  margin-bottom: 24px;
  display: flex;
  order: 1;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  @media screen and (min-width: 576px) {
    padding: 0px;
    margin-bottom: 0px;

    order: 3;
    flex-direction: row;
  }
  @media (max-width: 576px) {
    padding: 0px !important;
    margin-bottom: 0px;
  }

  > div {
    @media screen and (min-width: 576px) {
      order: 2;

      margin-bottom: 0px;
    }

    display: flex;
    order: 1;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 24px;
    @media (max-width: 550px) {
      margin-bottom: 10px;
    }
  }
`;

const TokenPriceBox = styled(Box)`
  margin-right: 20px;
  a {
    display: flex;
    align-items: center;

    color: inherit;
    text-decoration: none;
  }
  img {
    transition: transform 0.3s ease 0s;
    margin-right: 8px;
  }
  div {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }
`;
const Text = styled.span`
  margin: 0;
  color: #f6e7c9;
  text-shadow: 1px 1px 15px rgba(211, 104, 44, 0.76);
  font-family: Kanit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 31px;
  margin-bottom: 20px;
  @media (max-width: 880px) {
    margin-top: 0;
  }
`;

const TokenBuyBut = styled.a`
  margin-top: 30px;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 32px;
  padding: 0px 16px;
  text-decoration: none;
  &:hover {
    opacity: 0.65;
  }
  @media (max-width: 880px) {
    display: none;
  }
`;
const MobileTokenBuyBut = styled.a`
  display: none !important;
  margin-top: 10px;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 32px;
  padding: 0px 16px;
  text-decoration: none;
  &:hover {
    opacity: 0.65;
  }
  @media (max-width: 879px) {
    display: flex !important;
    align-self: baseline;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default Landing;
