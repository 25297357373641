import { AppBar, Toolbar, Box, Button, SvgIcon } from "@material-ui/core";
import ConnectMenu from "./ConnectMenu.jsx";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Elacity from "src/assets/images/elacity.svg";
import pasar from "src/assets/images/pasar.png";
import twitter from "src/assets/images/Twitter.png";
import telegram from "src/assets/images/Vector.png";
import elastos from "src/assets/images/elastos.png";
import { GiHamburgerMenu } from "react-icons/gi";
import "./topbar.scss";

function TopBar({ setNotification }) {
  const [hamburgeropen, setHamburgerOpen] = useState(false);

  const dialog = useRef();
  const hamburger = useRef();

  useEffect(() => {
    document.addEventListener("mouseup", function (event) {
      if (
        dialog.current &&
        !dialog.current.contains(event.target) &&
        !hamburger.current.contains(event.target)
      ) {
        setHamburgerOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    console.log(hamburgeropen);
  }, [hamburgeropen]);
  return (
    <>
      <SubHeader>
        <p>
          <span>PHISHING WARNING:</span> Check carefully and make sure you're
          visiting https://bunnypunk.io - bookmark the URL.
        </p>
      </SubHeader>
      <StyledContainer className="header-menu-div">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          pr={"16px"}
          pl={"16px"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"}>
              <Box mr={"10px"} mt={"8px"}>
                <img
                  src={"/logo.svg"}
                  width={"60px"}
                  height={"100%"}
                  alt={"logo"}
                />
              </Box>
              <Menus className="contents-menu">
                <Box>
                  <Link
                    className="link-a staking-bottom"
                    to={"/farms"}
                    style={{ textDecoration: "unset" }}
                  >
                    FARM
                  </Link>
                </Box>
                <a
                  target={"_blank"}
                  href="https://stakev2.bunnypunk.io/"
                  className="link-a staking-bottom"
                >
                  NFT STAKING
                </a>
                <Link className="link-a staking-bottom" to={"/liquidity"}>
                  ADD LIQUIDITY
                </Link>
              </Menus>
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <SocialButton>
              <a
                href="https://ela.city/marketplace/collections/0xe27934fb3683872e35b8d9e57c30978e1260c614"
                target="_blank"
              >
                <img
                  src={Elacity}
                  loading="lazy"
                  alt="elacity favicon"
                  class="image-social"
                />
              </a>
              <a
                href="https://pasarprotocol.io/collections/detail/0xE27934fB3683872e35b8d9E57c30978e1260c614"
                target="_blank"
              >
                <img
                  src={pasar}
                  loading="lazy"
                  alt="elacity favicon"
                  class="image-social"
                />
              </a>
              <a href="https://t.me/bunnypunkchat" target="_blank">
                <img
                  src={telegram}
                  loading="lazy"
                  alt="elacity favicon"
                  class="image-social"
                />
              </a>
              <a href="https://twitter.com/bunny_punk_nft" target="_blank">
                <img
                  src={twitter}
                  loading="lazy"
                  alt="elacity favicon"
                  class="image-social"
                />
              </a>
              <a
                href="https://esc.elastos.io/address/0x2f45C6Df50E7F8517dAc64943a2161bBCFBa1182/transactions"
                target="_blank"
              >
                <img
                  src={elastos}
                  loading="lazy"
                  alt="elacity favicon"
                  class="image-social"
                />
              </a>
            </SocialButton>
            <Box display={"flex"} alignItems={"center"} height={"64px"}>
              <ConnectMenu setNotification={setNotification} />
              <Hamburger
                onClick={() => setHamburgerOpen(!hamburgeropen)}
                ref={hamburger}
              >
                <GiHamburgerMenu />
              </Hamburger>
            </Box>
          </Box>
        </Box>
        <div ref={dialog}>
          <HamburgerMenu
            className="menu-topen-2"
            width={"100%"}
            open={hamburgeropen}
          >
            <Menus className="opened-menu-h" open={hamburgeropen}>
              <a
                target={"_blank"}
                href="https://bunnypunk.io/"
                className="link-a staking-bottom"
              >
                HOME
              </a>
              <Box>
                <Link
                  className="link-a staking-bottom"
                  to={"/farms"}
                  style={{ textDecoration: "unset" }}
                >
                  Farms
                </Link>
              </Box>
              <a
                target={"_blank"}
                href="https://stakev2.bunnypunk.io/"
                className="link-a staking-bottom"
              >
                NFT STAKING
              </a>
              <a
                target={"_blank"}
                href="https://bunnypunk.io/litepaper"
                className="link-a staking-bottom"
              >
                LITEPAPER
              </a>
              <a
                target={"_blank"}
                href="https://snapshot.org/#/bunnypunk.eth"
                className="link-a staking-bottom"
              >
                GOVERNANCE
              </a>
              <Link className="link-a staking-bottom" to={"/liquidity"}>
                ADD LIQUIDITY
              </Link>
            </Menus>
          </HamburgerMenu>
        </div>
      </StyledContainer>
    </>
  );
}

const SubHeader = styled(Box)`
  backdrop-filter: blur(5px);
  z-index: 10;
  display: flex;
  top: 0;
  position: fixed;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  box-shadow: 0px 1px 1px 0px rgba(9, 13, 20, 0.4),
    -4px -4px 8px 0px rgba(224, 224, 255, 0.04),
    8px 8px 24px 0px rgba(9, 13, 20, 0.4);
  width: 100%;
  > p {
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    margin: 0;
    > span {
      margin: 0;
      font-weight: 700;
    }
    @media (max-width: 768px) {
      font-size: 10px;
    }
    @media (max-width: 550px) {
      font-size: 8px;
    }
    @media (max-width: 450px) {
      font-size: 7px;
    }
  }
`;
const StyledContainer = styled(Box)`
  background: linear-gradient(
    178deg,
    rgba(59, 62, 66, 0.4) 0%,
    rgba(59, 62, 66, 0.4) 50%,
    rgba(33, 36, 41, 0.4) 75%
  );
  box-shadow: 0px 1px 1px 0px rgba(9, 13, 20, 0.4),
    -4px -4px 8px 0px rgba(224, 224, 255, 0.04),
    8px 8px 24px 0px rgba(9, 13, 20, 0.4);
  width: 100%;
  padding: 0 20px;
  @media screen and (max-width: 450px) {
    padding: 0;
  }
  @media screen and (max-width: 1175px) {
    > div:nth-child(1) > div:nth-child(1) > div {
      justify-content: start;
      > div {
        width: fit-content;
        margin-left: 10px;
      }
    }
  }
  position: fixed;
  top: 40px;
  z-index: 10;
`;

const Menus = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 100%;
  > div {
    position: relative;
    display: flex;
    align-items: center;
    height: auto;
    padding: 0px 16px;
    cursor: pointer;
    > a {
      font-size: 16px;
      font-weight: 400;
    }
    &:hover {
      background: transparent;
      border-radius: 16px;
    }
  }

  @media screen and (max-width: 1175px) {
    display: ${({ open }) => (open ? "" : "none")};
    align-items: center;
    flex-direction: column;
    max-width: unset;
    font-size: 16px;
    > div {
      padding: 5px;
    }
  }
`;
const Hamburger = styled.div`
  font-size: 24px;
  color: #f6e7c9;
  text-shadow: 1px 1px 15px rgb(211 104 44 / 76%);
  margin-top: 7px;
  margin-left: 20px;
  cursor: pointer;
  display: none;
  @media screen and (max-width: 1175px) {
    display: unset;
  }
`;

const HamburgerMenu = styled(Box)`
  transition: all 0.3s;
  height: ${({ open }) => (open ? "110px" : "0")};
  overflow: hidden;
  @media screen and (min-width: 1175px) {
    display: none;
  }
`;

const SocialButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media (max-width: 650px) {
    padding: 0 2px;
  }
  @media (max-width: 582px) {
    display: none;
  }
  > a {
    cursor: pointer;
    display: flex;
    padding: 13px;
    margin-right: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid rgba(241, 172, 93, 0.3);
    border-radius: 88px;
    box-shadow: inset 0 0 8px 0 rgba(241, 172, 93, 0.3);
    &:hover {
      border-color: rgba(241, 172, 93, 0.6);
      box-shadow: inset 0 0 8px 0 rgba(241, 172, 93, 0.6);
    }
    .image-social {
      height: auto;
      max-width: 100%;
      min-width: 18px;
    }
    @media (max-width: 650px) {
      width: 28px;
      height: 28px;
      .image-social {
        min-width: 12px;
      }
    }
  }
`;
export default TopBar;
